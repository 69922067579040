import React from 'react'
import { Seo } from '../components'
import useWindowSize from '../hooks/useWindowSize'
import { usePrismic } from '../context/PrismicContext'
import { Container, Wrapper } from '../styles/common'

const TermsPage = () => {
  const {
    prismicData: { prismicGeneral },
  } = usePrismic()

  const { width } = useWindowSize()

  return (
    <>
      <Seo title="terms-of-use" />
      <Wrapper>
        <Container style={{ flex: 1 }}>
          {width > 750 ? (
            <object
              data={prismicGeneral.term_of_use_url}
              type="application/pdf"
              style={{ flex: 1 }}
            />
          ) : (
            <Container padding="1rem" align="center" style={{ flex: 1 }}>
              <a href={prismicGeneral.term_of_use_url} target="_blank">
                {prismicGeneral.view_document ?? 'View Document'}
              </a>
            </Container>
          )}
        </Container>
      </Wrapper>
    </>
  )
}

export default TermsPage
